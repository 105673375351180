
import { reactive, onMounted, ref } from 'vue'
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import axios from "@/plugin/vue-axios";

import ReumTitle from '@/components/ReumLib/layout/ReumTitle.vue'
import ReumPaging from '@/components/ReumLib/UI/ReumPaging.vue'
import ReumPagesize from '@/components/ReumLib/UI/ReumPagesize.vue'
import ReumDatePicker from '@/components/ReumLib/UI/ReumDatePicker.vue'
import ReumLoading from '@/components/ReumLib/UI/ReumLoading.vue'

import genoplan_status from '@/components/js/genoplan_status.js'

export default {
    name: 'Home',
    components: {
        ReumTitle,
        ReumPaging,
        ReumPagesize,
        ReumDatePicker,
        ReumLoading,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const toast = useToast();
        const xlsFile = ref(null);
        const sample = reactive({
            files: null,
            list: [{id_after:null, id_before:"", sn:"", sn_after:"", well_no: "",  dna_conc:"", qc:"", status:""}],
            excelcheck : () => {
                let formData = new FormData();
                if(xlsFile.value.files.length<1) {
                    toast.error("파일을 첨부해주세요.");
                    return;
                }
                formData.append("nfile", xlsFile.value.files[0]);

                axios.post("/api/SampleCtrl/excelupload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    if(res.data.err == 0) {
                        comp.page = 1;
                        comp.getList();
                        toast.success("데이터를 불러왔습니다.");
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
            excelupload: () => {
                xlsFile.value.click();
            },
        });

        const comp = reactive({
            loading:false,
            page:1,
            total: 1,
            pagesize: 50,
            status: [],
            search: {
                status: "",
                report_status: "",
                datefield: "date_of_collection",
                sdate: "",
                edate: "",
                text : ""
            },
            list:[],
            setPagesize: (val) => {
                console.info("FF", val);
                comp.page = 1;
                comp.total = 0;
                store.commit("setPagesize", val);
                comp.getList();
            },
            add: () => {
                router.push({path: "/SampleCtrl/new"});
            },
            showInfo: (itm) => {
                router.push({path: "/SampleCtrl/" + itm.id});
            },
            dateToString(pdate) {
                return (pdate == null?"":[pdate.getFullYear(),((pdate.getMonth() + 1)>9 ? '' : '0') + (pdate.getMonth() + 1),(pdate.getDate()>9 ? '' : '0') + pdate.getDate()].join(''));
            },
            getExcelSample: () => {
                location.href="/rest/api/common/getExcelSample?sample=SampleCtrl";
            },
            getExcel: () => {
                comp.loading = true;
                let params = {
                    page: comp.page,
                    pagesize: comp.pagesize,
                    ...comp.search
                }
                axios({url:"/api/SampleCtrl/exceldown", method:'GET', responseType: 'blob', params: params}).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let today = new Date();
                    link.setAttribute('download', 'SampleCtrl'+comp.dateToString(today)+'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    comp.loading = false;
                });

            },
            getList: () => {
                comp.list = [];
                let params = {
                    page: comp.page,
                    pagesize: comp.pagesize,
                    ...comp.search
                }
                axios.get("/api/SampleCtrl/list", {params : params}).then((res) => {
                    if(res.data.err == 0) {
                        comp.list = res.data.list;
                        comp.total = res.data.total;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
        });
        onMounted(() => {
            comp.status = genoplan_status.SampleCtrl;
            comp.pagesize = store.state.pagesize;
            comp.getList();
        });
        return {
            comp,store,xlsFile, sample
        };
    },
};
